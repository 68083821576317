export default {
  computed: {
    bookATourTitle() {
      const fromSettings = this.organization?.tours?.title;
      const title = fromSettings || "Book a tour";
      const durationMin = this.bookATourDuration / 60;
      return title + " - " + durationMin + " minutes";
    },
    /**
     * Timeblock length
     */
    bookATourDuration() {
      // Respect the settings, but require at least 5 minutes
      const fromSettings = this.organization?.tours?.duration_sec;
      if (fromSettings >= 5 * 60) {
        return fromSettings;
      }
      // Default 45 minutes
      return 45 * 60;
    },
    /**
     * Timeblock shift (interval between the start times)
     */
    bookATourStep() {
      // Default 30 minutes, but allow smaller step for 15 minute tours
      const fromSettings = this.organization?.tours?.duration_sec;
      return fromSettings == 15 * 60 ? fromSettings : 30 * 60;
    },
  },
};
